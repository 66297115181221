@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

:root {
  --main-color: #4eb0e2;
  --hover-color: #4eb1e2b0;
  --main-bg-color: #fbfafa;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

body {
  background-color: var(--main-bg-color);
}

.App {
  /* width: 70%;
  margin: 0 auto; */
}

.header-stiky-transition {
  height: 15px;
  border-bottom: solid 1px #f5f5f5;
}

