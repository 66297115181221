.heading-main-container {
  background-color: #fafdff;
}

.about-content-container {
  display: none;
  margin-top: 50px;
}

.our-tech-content-container {
  /* margin-top: 50px; */

  /* background-color: #f2faff; */
  /* background-image: url(""); */
  /* background: rgb(15, 13, 51); */

  /* background: linear-gradient(
    90deg,
    rgba(15, 13, 51, 1) 0%,
    rgba(0, 213, 255, 0.163) 100%
  ); */

  /* color: #0a7ea4; */
}

.header-container {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fbfafaf1;
  z-index: 999;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  border-bottom: #000000 1px solid;
}

.headliner {
  min-width: fit-content;
  background: 50% 100%/50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 3vw;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
    color: #f2faff;
  }
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
    color: #f2faff;
  }
}
/* @-webkit-keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
} */

.learn-more {
  animation: fadeIn 25s;
  -webkit-animation: fadeIn 25s;
  -moz-animation: fadeIn 25s;
  -o-animation: fadeIn 25s;
  -ms-animation: fadeIn 25s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logo {
  width: 150px;
  border-right: #000000 1px solid;
}

.logo img {
  width: 100px;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  cursor: pointer;
}

.nav-container {
  display: flex;
  width: 70%;
  margin: 0 auto;
  justify-content: space-between;
}

.nav-container .nav-link {
  text-decoration: none;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  /* margin: 10px; */
  padding: 10px;
  /* color: var(--main-color); */
  color: #000000;
  transition: 500ms;
}

.nav-link-active {
  color: #4db1e2;
  text-decoration: none;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 10px;
  transition: 500ms;
}

.nav-links {
  margin-top: 20px;
}

.nav-container .nav-link:hover {
  color: var(--main-color);
}

.contact-link {
  background-color: var(--main-color);
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 10px;
  padding: 10px;
  transition: 1s;
}

.contact-link:hover {
  background-color: var(--hover-color);
}

.menu-items-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 90vh;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9665616089229414) 18%,
    rgba(222, 247, 255, 1) 100%
  );

  border-top: #000000 1px solid;
}

.menu-items {
  width: 60%;
  margin: 40px auto;
  display: flex;
}

.menu-title h1 {
  color: #000000;
  font-size: 50px;
  font-weight: 100;
}

.cancel-btn button {
  margin: 20px;
  background-color: #00000000;
  color: #323232dc;
  border: none;
  font-size: 40px;
  font-weight: 100;
  transition: 500ms;
}

.cancel-btn button:hover {
  cursor: pointer;
  font-size: 43px;
}

.login-link {
  display: inline;
  width: fit-content;
  background-color: #4db1e2;
  color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 10px;
  padding: 10px;
  transition: 1s;
}

/* section 2 */

.image-gallery {
  max-width: 600px;
  margin-top: 50px;
  width: 550px;
}

.home-container {
  display: flex;
  justify-content: space-between;
  height: 85vh;
  /* background-color: #f3f3f3; */
  /* padding: 10px; */

  width: 100%;
  /* margin: 0 auto; */
}

.pattern {
  width: 100%;
  min-height: 85vh;
  color: #000000;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgba(240, 240, 240, 0.5) 100%
  );
}

.slider {
  min-width: 100%;
}

.slid {
  width: 100%;
  height: 85vh;
  color: #f2faff;
  background: rgb(15, 13, 51);
  background: linear-gradient(
    90deg,
    rgba(15, 13, 51, 1) 0%,
    rgba(0, 213, 255, 0.163) 100%
  );
}

.slid-content {
  width: 70%;
  margin: 18vh auto;
}

.slid-content .section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.slid-content .section h1 {
  width: 75%;
  margin-top: 45px;
  /* font-size: 50px; */
  font-weight: 100;
}

.slid-content .section span {
  font-size: 20px;
  text-transform: uppercase;
}

.slid-content .section strong {
  /* color: linear-gradient(
    90deg,
    rgba(15, 13, 51, 1) 0%,
    rgba(0, 213, 255, 0.163) 100%
  ); */
  font-weight: 900;
}

.slid-content .section .link {
  width: fit-content;
  margin-top: 45px;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  display: flex;
}

.call-to-action {
  width: fit-content;
  margin-top: 45px;
  text-decoration: none;
  color: #4db1e2;
  font-size: 15px;
  display: flex;
}

.slid-content .section .link .icon {
  margin-left: 10px;
  height: 15px;
  position: relative;
  top: 2px;
}

#icon {
  left: 0px;
  transition: transform ease 300ms;
}

.call-to-action:hover #icon {
  transform: translate(10px, 0);
}

.link:hover #icon {
  /* left: 5px; */
  transform: translate(10px, 0);
}

.heading-content {
  margin-top: 100px;
  max-width: 500px;
}

.heading-content h1 {
  font-size: 40px;
  font-weight: 200;
}

/* message section */

.message-content {
  width: 70%;
  margin: 0 auto;
}

.message-content .section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.message-content .section h1 {
  width: 60%;
  margin-top: 80px;
  font-size: 45px;
  font-weight: 100;
}

.message-content .section span {
  width: 64%;
  font-size: 20px;
  margin-top: 40px;
}

.our-impact-figure {
  font-size: 100px;
  color: #000000;
}

.message-content .section strong {
  /* color: linear-gradient(
    90deg,
    rgba(15, 13, 51, 1) 0%,
    rgba(0, 213, 255, 0.163) 100%
  ); */
  font-weight: 900;
}

.message-content .section .link {
  width: fit-content;
  margin-top: 40px;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  display: flex;
}

.message-content .section .link .icon {
  margin-left: 10px;
  height: 15px;
  position: relative;
  top: 2px;
}

.light--mode {
  color: var(--main-color);
}
.flex {
  display: flex;
}

.box-shadow {
  padding: 8px;
  border-radius: 50px;
  border: #000000 1px dashed;
  /* background-color: #00000068; */
}

/* About section */

figure {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-auto-rows: auto; */
  grid-auto-flow: dense;
  justify-items: stretch;
  align-items: stretch;
  gap: 2svmin;
}

img {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.aboutus-container {
  margin-top: 100px;
  height: 80vh;
  display: flex;
  justify-content: space-between;

  width: 70%;
  margin: 0 auto;
}

.image-content img {
  max-width: 500px;
}
.about-content {
  width: 50%;
  height: fit-content;
  padding: 30px;
  padding-top: 0;
  /* background-color: #f2f2f2; */
}

.about-content--text {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.our-tech-container {
  margin-top: 100px;
  height: 100vh;
  /* text-align: center; */
  width: 70%;
  margin: 0 auto;
}

.our-tech-content {
  padding-top: 50px;
}

.no-space {
  justify-content: space-evenly;
}

/* .our-tech-content h1 {
  padding: 10px;
  font-size: 40px;
} */

.our-tech-content .section h1 {
  width: 70%;
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: 100;
}

.our-tech-content .section p {
  margin-top: 40px;
  width: 70%;
  font-size: 20px;
}

.tech-stack-slider {
  /* background-color: #F8F8F8; */
  margin-top: 30px;
  width: 100%;
  height: 30vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-tech-stacks--slid {
  display: flex;
  background-color: #fbfafa;
  z-index: 999;
  justify-content: space-between;
  width: 100%;
  height: 30vh;
}

.customer-slider {
  background-color: #f8f8f8;
  margin-top: 30px;
  width: 100%;
  height: 18vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer--slid {
  display: flex;
  background-color: #f8f8f8;
  z-index: 999;
  justify-content: space-between;
  width: 100%;
  height: 18vh;
}

.figures {
  display: flex;
  justify-content: space-between;
  /* margin-top: 70px; */
}

.our-tech-content .figures {
  margin-top: 70px;
}

.figure {
  min-width: 100px;
  margin: 10px;
  text-align: center;
  color: var(--main-color);
}

.our-impact-figure {
  font-weight: 100;
  color: var(--main-color);
  margin-top: 30px;
}

.figure h1 {
  font-size: 50px;
}

.figure p {
  font-size: 20px;
}

.mobile-nav-links {
  margin-top: 15px;
  display: none;
}

.mobile-menu-icon {
  cursor: pointer;
}

.mobile-link-items {
  background-color: #ffffff;
  border-top: #000000 1px solid;
  width: 200px;
  height: 100vh;
  position: absolute;
  top: 60px;
  right: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.child-links {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
}

.child-links .nav-link {
  text-decoration: none;
  font-size: 20px;
  margin: 5px;
  color: #000000;
}

.child-links .nav-link:hover {
  text-decoration: underline;
  color: #4db1e2;
}

.section .card-group {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  justify-content: space-between;
  color: #000000;
}

.card-out {
  margin-top: 20px;
  max-width: 250px;
  padding: 1.5px;
  margin-right: 30px;
  position: relative;
  background: linear-gradient(
    90.38deg,
    #0078c2 4.77%,
    #0047ff 42.63%,
    #8453d2 65.91%
  );
  border-radius: 10rem;
}

.card-inside {
  background: #ffffff;
  color: #000000;
  padding: 3.5rem;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10rem;
}

.card-inside-inquiry {
  background: #e4f2f7;
  color: #000000;
  min-width: 100%;
  padding: 0.7rem;
  border: none;
  font-size: 14px !important;
  cursor: pointer;
  border-radius: 10rem;
}

.card-out:hover {
  background: linear-gradient(90.38deg, #0078c24f 4.77%, #8453d252 30.91%);
}

.customer-logo {
  width: 100px;
  aspect-ratio: unset;
}

.footer {
  border-top: #000000 1px solid;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9665616089229414) 18%,
    rgba(222, 247, 255, 1) 100%
  );
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-height: 70vh;
  margin: 0 auto;
  justify-content: space-between;
}

.footer-nav-container {
  min-height: 65vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cat {
  margin-top: 30px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 150px;
}

.footer-links .link {
  text-decoration: none;
  margin-top: 20px;
  margin-left: 0;
  font-size: 15px;
  color: #000000;
}

.footer-links .link:hover {
  color: #4db1e2;
  text-decoration: underline;
}

.footer-link-icons {
  display: flex;
  flex-wrap: wrap;
  max-width: 100px;
  justify-content: space-between;
  margin-top: 10px;
}

.footer-link-icons .link {
  margin-top: 10px;
  padding: 5px;

  color: #000000;
}

.footer-link-icons .link:hover {
  color: #4db1e2;
}

.footer-logo {
  width: 200px;
  /* border-right: #000000 1px solid; */
}

.footer-logo img {
  width: 130px;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  cursor: pointer;
}

.rights {
  width: 200px;
  margin-bottom: 50px;
}

.rights p {
  font-size: 10px;
}

.heading-container {
  min-height: 70vh;
}

.heading--content {

}

.heading--content {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
  
  h1 strong {
    font-size: 70px;
    font-weight: 100;
  }
  span {
    font-size: 25px;
    max-width: 70%;
    font-weight: 100;
    margin-top: 30px;
  }
  .icon {
    position: relative;
    top: 3px;
    margin-left: 10px;
  }
}

.our-capabilities-container {
  display: flex;
}

.main-section-content {
  display: flex;
  flex-direction: column;
  width: 60%;
  min-height: 80vh;
  margin: 0 auto;
  padding-top: 100px;

  h1 strong {
    font-size: 40px;
    font-weight: 100;
  }
  span {
    font-size: 25px;
    max-width: 70%;
    font-weight: 100;
    margin-top: 30px;
  }
  .icon {
    position: relative;
    top: 3px;
    margin-left: 10px;
  }

  .our-capabilities-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 100px;

    span {
      color: var(--main-color);
      padding: 30px;
      margin: 10px;
    }
  }
}

.links {
  .link {
    min-width: 300px;
    padding: 15px;
    padding-bottom: 7px;
    padding-left: 0;
    text-decoration: none !important;
    color: #000000;
    strong {
      display: flex;
      justify-content: space-between;
    }
    border-bottom: #000000 1px solid;
  }

  .link_active {
    min-width: 300px;
    padding: 15px;
    padding-bottom: 7px;
    padding-left: 0;
    text-decoration: none !important;
    color: var(--main-color);
    border-bottom: var(--main-color) 1px solid;
    strong {
      display: flex;
      justify-content: space-between;
    }
  }

  .link:hover {
    color: var(--main-color);
  }
}

.list-switch-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.switch-detail {
  min-width: 400px;
  margin-left: 50px;
  h2 {
    margin-bottom: 30px;
  }

  p {
    font-size: 17px;
  }
}
/* contact us */
.form-inquiry {
  margin-top: 40px;
  min-height: 100vh;
}

.form-items {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.form-items--item {
  width: 47%;
}

.form-items--item-Inquiry {
  width: 100%;
}

.consent-box {
  display: flex;
  font-size: 15px;
  font-weight: 200;
  margin-top: 40px;
  p {
    margin-top: 30px;
    padding: 10px;
  }
}

/* contact us */

@media (max-width: 1100px) {
  /* nav */
  .nav-links {
    display: none;
  }

  .mobile-nav-links {
    display: block;
  }
  /* nav */
}

@media (max-width: 1000px) {
  .home-container {
    flex-direction: column;
    height: 100vh;
  }
  .aboutus-container {
    flex-direction: column;
    /* height: 100vh; */
  }

  /* services-section */
  .card-container {
    flex-wrap: wrap;
  }

  /* services-section */
}

@media (min-width: 500px) {
  figure {
    grid-template-columns: repeat(2, 1fr);
  }
  img:nth-of-type(11) {
    grid-row: span 2 / auto;
  }

  .slid-content .section {
    width: 100%;
    padding: 0;
    /* padding-top: 100px; */
  }
}

@media (min-width: 1000px) {
  figure {
    grid-template-columns: repeat(4, 1fr);
    gap: 1svmin;
  }

  img:where(:nth-of-type(3), :nth-of-type(12)) {
    grid-row: span 2 / auto;
    grid-column: span 2 / auto;
  }

  img:nth-of-type(5) {
    grid-column: span 2 / auto;
  }

  img:nth-of-type(15) {
    grid-column: span 2 / auto;
  }
}
